$(function() {
  //SPヘッダー,クリックでナビオープン
  $('#headerToggleButton').on('click', function() {
    $('.l-spGlobalNav').toggleClass('js-active');
    $(this).toggleClass('js-active');
  });

  //横スクロールダミーと連携
  let scrollWidth = $('.p-item__table').innerWidth();
  $('.p-item__scrollBar div').css('width', scrollWidth);
  $('.p-item__scroll').scroll(function(){
    $('.p-item__scrollBar').scrollLeft($('.p-item__scroll').scrollLeft());
  });
  $('.p-item__scrollBar').scroll(function(){
    $('.p-item__scroll').scrollLeft($('.p-item__scrollBar').scrollLeft());
  });

  // ページ内スクロール
  const headerHeight = $('.l-header').innerHeight();
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr('href');
    var target = $(href == '"#"' || href == '""' ? 'html' : href);
    var position = target.offset().top - headerHeight;
    $('html, body').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  // slick
  $('.top-hero__slider').slick({
    autoplay: true,
    dots: true,
    arrows: true
  });

  // animation
  var triggerPoint = 1;
  var scrollAnimationElm = document.querySelectorAll('.p-fade');
  var scrollAnimationFunc = function() {
    for(var i = 0; i < scrollAnimationElm.length; i++) {
      var triggerMargin = triggerPoint;
      var elm = scrollAnimationElm[i];
      var showPos = 0;
      if(elm.dataset.fade_trigger) {
        showPos = document.querySelector(elm.dataset.fade_trigger).getBoundingClientRect().top + triggerMargin;
      } else {
        showPos = elm.getBoundingClientRect().top + triggerMargin;
      }
      if (window.innerHeight > showPos) {
        elm.classList.add('js-trigger');

      }
    }
  }
  window.addEventListener('load', scrollAnimationFunc);
  window.addEventListener('scroll', scrollAnimationFunc);
});